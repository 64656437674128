@media only screen and (min-width: 1024px) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        justify-content: space-between;
        font-family: var(---font-style-sans);
        align-items: center;
    }
}
@media only screen and (max-width: 600px) {
    .mobresponsive-height .landingpage-banner {
        height: auto !important;
        min-height: 100vh!important;
        display: flex;
        align-items: center;
    }
}

@media only screen and (max-width: 800px) {
    .banner-content h1 {
        font-weight: 700;
        font-size: 26px !important;
        line-height: 32px !important;
        font-family: var(---font-style-sans);
    }

   .mobresponsive-height .landingpage-banner {
        height: auto !important;
        min-height: 86vh;
        display: flex;
        align-items: center;
    }

    .banner-content p {
        font-family: var(---font-style-sans);
        font-weight: 400;
        font-size: 14px !important;
        line-height: 19px !important;
    }

    .about-content {
        margin-top: 50px;
    }

    .img-about {
        min-width: auto !important;
    }
    .about-content h1{
        font-size: 35px!important;
    }
    .mob-fixed-bg{
        position: fixed!important;
        background-color: #fff!important;
        z-index: 999!important;
        width: 100%!important;
    }
    .banner-content{
        margin-top: 50px;
    }
    .about-content h1{
        margin-top: 70px;
    }
}
@keyframes fadein {
    from { opacity: 0.9; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.header-nav {
    top: 0;
    transition: top 0.1s ease-in-out;
    background: transparent;
  }
  .sticky-header{
    position: fixed!important;
    width: 100%;
    z-index: 999999;
    background-color: #fff;
    /* color: #000!important; */
    animation: fadein ease-in-out 0.1s;
    transition: background 0.2s,height 1s;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;

  }
  #header.sticky {
    position: fixed;
    width: 100%;
    background: #fff;
  }
.navbar-dark .navbar-nav .nav-link {

    text-align: center;
}

.img-banner1 {
    background: url('../../assets/img/bglanding.png');
    height: 100vh;
    background-size: cover;
}

html {}

.navbar-dark .navbar-nav .nav-link {
    color: rgb(0 0 0);
}

.navbar-dark .navbar-toggler-icon {
    filter: invert(1) !important;
}

/* .active{
    background:transparent!important;
    color:#000;
} */
.navbar-nav {
    gap: 40px;
    align-items: center;
}

.navlink-cus {
    color: #000;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
}

.btn-register {
    margin-right: 30px;
    background-color: #E0701A;
    font-family: var(---font-style-sans);
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    padding: 10px 30px;
    width: 100%;
    border: none;
    color: #FFFFFF;
}

.landingpage-banner {
    height: 80vh;
    display: flex;
    align-items: center;
}

.login-box-css {
    background-color: #FFFFFF;
    background: #FFFFFF;
    border: 1px solid #E0701A;
    box-shadow: -8px 5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 99;
}

.banner-content h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 67px;
    font-family: var(---font-style-sans);
}

.banner-content h1 span {
    color: #E0701A;
}

.banner-content p {
    font-family: var(---font-style-sans);
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;

}

.btn-login {
    width: 96%;
    background-color: #E0701A;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    color: #FFFFFF;
}

.form-box-content h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    font-family: var(---font-style-sans);
}

.form-box-content h6 {
    font-weight: 400;
    font-size: 16px;
    font-family: var(---font-style-sans);
}

.form-box-content p {
    font-weight: 400;
    font-size: 16px;
    color: #F7A340;
    font-family: var(---font-style-sans);
}

.img-about {
    min-width: 445px;
    width: 100%;
}

.about-content h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 67px;
    font-family: var(---font-style-sans);
}

.about-content span {
    color: #E0701A;
}

.about-content p {
    font-family: var(---font-style-sans);
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
}

.about-content h1 {
    font-weight: 600;
    font-size: 50px;
    line-height: 67px;
    font-family: var(---font-style-sans);
}

.howitworks-box-css {
    text-align: center;
    display: flex;
    border: 1px solid #f0f0f0;
    border-radius: 28px;
    border-width: 0.1px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon-box-home {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 54.23px;
    height: 54.23px;
    background: #E0701A;
    transform: rotate(-44.72deg);
}

.icon-howitsworks {
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    transform: rotate(44.72deg) !important;
}

.howitworks-box-css h1 {
    font-weight: 600;
    font-size: 23px;
    line-height: 27px;
    color: #E0701A;
    font-family: var(---font-style-sans);
}

.howitworks-box-css p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.022em;
    text-align: center;
    color: #9e9e9e;
    padding: 13px;
}

.howitworks-box-css:hover {
    transition: all ease-in-out 0.5s;
    background-color: #E0701A;
}

.howitworks-box-css:hover p {
    color: #FFFFFF;
}

.howitworks-box-css:hover h1 {
    color: #FFFFFF;
}

.howitworks-box-css:hover .icon-box-home {
    background-color: #ffff;
}

.howitworks-box-css:hover .icon-howitsworks {
    color: #E0701A;
}

.bg-footer-landing {
    margin-top: 100px;
    background-color: #E0701A;
    color: #FFFFFF;
}

.footer-copyright p {
    font-weight: 400;
    font-size: 17px;
    line-height: 16px;
    font-family: var(---font-style-sans);
}