/* .video-player {
    width: 800px;
    height: 450px;
    background-color: #333;
    color: #fff;
    margin: 0 auto;
}

video {
    width: 100%;
    height: 100%;
}

video::-webkit-media-controls {
    background-color: transparent;
    color: #fff;
}

video::-webkit-media-controls-play-button,
video::-webkit-media-controls-pause-button {
    background-image: url('../../../assets/img/Vector.svg');
    width: 50px;
    height: 50px;
}

video::-webkit-media-controls-mute-button {
    width: 50px;
    height: 50px;
}

video::-webkit-media-controls-volume-slider {
    background-color: #ddd;
    width: 100px;
}

video::-webkit-media-controls-volume-slider-thumb {
    background-color: #0096ff;
    width: 10px;
    height: 10px;
}

video::-webkit-media-controls-timeline {
    background-color: #424242;
}

  video::-webkit-media-controls-progress-value {
      background-color: orange;
      width: 20px;
      height: 20px;
  }

  .video-player {
      position: relative;
  }

  video {
      width: 100%;
  }

  .timer {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
  }

  .timer>div {
      flex-grow: 1;
      text-align: center;
  }

  .timeline {
      position: relative;
      width: 100%;
      height: 20px;
      background-color: gray;
  }

  .timeline-progress {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: orange;
      height: 100%;
  } */
.video-player {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    padding: 10px;
}

video {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
}

.controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 5px;
    background-color: rgba(0, 0, 0, 0.5);
}

.play-button {
    color: white;
    cursor: pointer;
    background: gray;
    padding: 6px 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.timeline {
    height: 20px;
    background-color: gray;
    width: 100%;
    position: relative;
}

.timeline-progress {
    height: 20px;
    background-color: orange;
    position: absolute;
    left: 0;
    top: 0;
}

.fullscreen-button {
    color: white;
    cursor: pointer;
}

.volume-control {

    /* background-color: orange; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

input[type=range] {
    -webkit-appearance: none;
    appearance: none;
    width: 90%;
    background: #ddd;
    height: 6px;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    background: orange;
    border-radius: 50%;
    cursor: pointer;
}

input[type=range]::-moz-range-thumb {
    width: 14px;
    height: 14px;
    background: orange;
    border-radius: 50%;
    cursor: pointer;
}

.desktop-viewdetails-table {
    display: block;
}

.mob-viewdetails-table {
    display: none;
}
.mt-editor-movetop{
    margin-top: -15px;
}
.spinner-video-buffer{
    position: absolute;
    z-index: 1000000;
    color: #ffffff;
    left: 42%;
    margin-top: calc(23% - 20px);
}

.progress-bar-container-video-buffer {
    width:100px;
    height: 10px;
    background-color: #f2f2f2;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    /* transform: rotatex(180deg); */
    /* transform-origin: top; */
  }
  .video-percentage-main{
    position: absolute;
    z-index: 100000;
    /* margin-top: calc(30% - 3px); */
    height: calc(98% - 30px);
    width: 100%;
    background: #00000045;
    justify-content: center;
    align-items: center;
    /* left: 31%; */ 
  }
  .progress-bar-fill-buffer {
    width: 100%;
    height: 10px;
    background-color: #F7A340;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    transition: height 0.5s;
    
  }
  .CircularProgressbar .CircularProgressbar-path{
    stroke: #F7A340!important;
  }
  .CircularProgressbar .CircularProgressbar-text{
    fill: #fff!important;
  }
@media screen and (max-width:900px) {
    .breadcrumps-box {
        padding: 30px;
    }

    .desktop-viewdetails-table {
        display: none !important;
    }

    .mob-viewdetails-table {
        display: block !important;
    }
}

@media screen and (max-width:600px) {
    .controls {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3px !important;
        gap: 3px !important;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .play-button {
        color: white;
        cursor: pointer;
        background: gray;
        padding: 4px 12px !important;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pausebutton-icon {
        width: 10px;
    }

    .playbutton-icon {
        width: 10px;
    }

    .timeline-progress {
        height: 12px !important;
    }

    .timeline {
        height: 12px !important;
    }

    .align-interview-documents {
        justify-content: space-between;
    }
        .add-editor-cancel-align {
            justify-content: center!important;
        }
}
@media screen and (max-width: 1100px) {
    .add-editor-cancel-align {
    justify-content: center !important;
}
.breadcrumps-box{
        padding: 25px 24px;
}
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin: 0 5px;
    width: 30px;
    height: 30px;
    /* padding: 5px 10px; */
    cursor: pointer;
}

.pagination-button:hover {
    background-color: #ccc;
}

.active-notes {
    background-color: #1f1da2;
    color: white;
}

.pagination-ellipsis {
    margin: 0 5px;
}
.edit-delete-aling{
    display: flex;
    gap: 10px;
}