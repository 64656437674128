.header-general-instruction {
    background-color: #112B3C;
    color: #fff;
    padding: 5px 5px;
    font-size: 24px;
    font-family: var(---font-style-sans);
}
.header-general-instruction-allthebest {
    /* background-color: #112B3C; */
    color: #000;
    padding: 5px 5px;
    font-size: 24px;
    font-family: var(---font-style-sans);
}

.border-for-instruction {
    border: 1px solid #112B3C;
}

.videoinitialState {
    height: 30vh
}

/* .img-banner-interview{
    height: 100vh;
    background-size: cover;
    background-image: url('../../../assets/img/bg-landing.jpg');
} */
.list-of-instruction {
    padding: 4px 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    font-family: var(---font-style-sans);
}

.img-cus-video-icon {
    width: 50%;
}

.question-font-cus {
    font-size: 24px;
    font-family: var(---font-style-sans);

}

.instruction-box-design ul li {
    /* padding: 10px 20px; */
}

.instruction-box-design ul {
    padding: 5px 40px;
}

.bg-dark-video {
    background-color: #e1e1e1;
}

.videoinitialState {
    background-color: #e1e1e1;
    /* min-height: 640px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-start-interview {
    background-color: #e0701a;
    font-family: var(---font-style-sans);
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    padding: 10px 30px;
    /* width: 100%; */
    margin-top: 20px;
    border: none;
    color: #fff;
}

.row-eq-height {
    display: flex;
    flex-wrap: wrap;
    min-height: 80vh;
}

.padding-mob-desktop-interview-header {
    padding: 10px 20px;
}

.progress-bar {
    height: 10px;
    background-color: #F7A340;
    transition: width 0.1s ease-in-out;
}

.progress-bar-timeleft {
    height: 10px;
    background-color: #dcdcdc;
    width: 25%;
    transition: width 0.1s ease-in-out;
}

.progress-timeleft {
    display: flex;
    height: 10px;
    overflow: hidden;
    font-size: .75rem;
    font-family: var(---font-style-sans);
    background-color: #F7A340;
    border-radius: 0.25rem;
}

/* .footer-position {
    position: absolute;
    bottom: 0;
    width: 100%;   
} */
.video-recorder-align {
    transform: scaleX(-1);
}

.video-container {
    transform: scaleX(-1);
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    border: 1px solid #ccc;
}

.lazy-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
}



.lazy-loading.is-loading {
    opacity: 1;
}

.lazy-loading .spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-top: 3px solid #666;
    border-right: 3px solid transparent;
    animation: spin 0.8s linear infinite;
}

.lazy-loading.is-loading .spinner {
    display: block;
}

.lazy-loading.is-loading+video {
    opacity: 0 !important;
}

.lazy-loading-interview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
    height: 110vh;
    overflow: hidden;
}

.spinner-interview {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-top: 3px solid #666;
    border-right: 3px solid transparent;
    animation: spin-interview 0.8s linear infinite;
}

.img-sizevidoe-audio {
    width: 40%;
    /* margin-top: 20px; */
    cursor: pointer;
    text-align: center;
}

.img-size-audio {
    width: 40%;
    margin-top: 20px;
    cursor: pointer;
}

.caption-for-img {
    font-family: var(---font-style-sans);
    font-size: 14px;
    margin-top: 4px;
}

@keyframes spin-interview {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.video-container video.is-visible {
    opacity: 1;
}

.video-container video:not(.is-visible) {
    opacity: 0;
}

.progressbar-timeleft-align {
    display: flex;
    align-items: center;
    gap: 12px;
}

.response-time-text {
    font-size: 13px;
}

.thankyou-h-100 {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thank__page__ui {
    width: 34%;
    height: auto;
    min-height: 49%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
}

.browser__page__ui {
    width: 40%;
    height: 60%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
}

.box-of-thankyoupage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.box-of-browserchange {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.img-icon-thankyou {
    width: 25%;
}

.box-of-thankyoupage h1 {
    font-weight: 600;
    font-size: 43px;
    line-height: 64px;
    font-family: var(---font-style-sans);
    color: #000000;
    margin-top: 10px;

}

.box-of-browserchange h1 {
    font-weight: 600;
    font-size: 30px;
    /* line-height: 64px; */
    font-family: var(---font-style-sans);
    color: #000000;
    margin-top: 10px;
    text-align: center;
}

.box-of-thankyoupage p {
    font-weight: 400;
    font-size: 18px;
    /* line-height: 21px; */
    color: #000000;
    text-align: center;
    font-family: var(---font-style-sans);
}

.box-of-browserchange p {
    font-weight: 400;
    font-size: 18px;
    /* line-height: 21px; */
    color: #000000;
    text-align: center;
    font-family: var(---font-style-sans);
}

video.is-visible {
    max-height: 400px;
}
.video-interview-height{
    max-height: 400px;
}

@media screen and (max-width:600px) {
    .border-for-instruction {
        margin-top: 5px;
    }


    .mt-sm-cus-10 {
        margin-top: 20px;
    }


    .videoinitialState img {
        width: 100px;
    }

    .bg-dark-video {
        min-height: 210px;
    }

    .videoNo-mob-height {
        height: 280px;
    }


    .flex-direction-mob {
        flex-direction: column;
    }
    .video-interview-height{
        margin-top: 10px!important;
    }
}

@media screen and (max-width:800px) {
    .padding-mob-desktop-interview-header {
        padding: 10px 5px !important;
    }
    .videoNo-mob-height{
        margin-top: 20px!important;
    }
    .video-interview-height{
        margin-top: 20px!important;
    }
    .thank__page__ui {
        width: 97%;
        height: 73%;
    }

    .browser__page__ui {
        width: 97%;
        height: 73%;
    }

    .instruction-box-design ul {
        padding: 0px 27px !important;
    }

    .list-of-instruction {
        padding: 9px 0px;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 20px !important;
    }

    .row-eq-height {
        flex-direction: column;
    }
}

/* 
.row-eq-height>[class*='col-']>div {
    height: 100%;
}

@media (min-width: 992px) {
    .row-eq-height {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
} */