/* .signup-bg-img {
  background: url(./assets/img/signinbg.png);
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.did-floating-label-content {
  position: relative;
  margin-bottom: 20px;
}

.did-floating-label {
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #989898;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 14px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-input,
.did-floating-select {
  font-family: var(---font-style-sans);
font-weight: 400;
font-size: 18px;
line-height: 30px;
color:var(--secondry-color);
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background: #fff;

  border: 1px solid #989898;
  border-radius: 7px;
  box-sizing: border-box;
}

.did-floating-input:focus,
.did-floating-select:focus {
  outline: none;
  border: 1px solid #4549A2;
}

.did-floating-input:focus~.did-floating-label,
.did-floating-select:focus~.did-floating-label {
  top: -14px;
  font-size: 16px;
  color: #4549A2;

}


.did-floating-input:not(:placeholder-shown)~.did-floating-label {
  top: -14px;
  font-size: 16px;
  
}





.did-error-input .did-floating-input,
.did-error-input .did-floating-select {
  border: 2px solid #9d3b3b;
  color: #9d3b3b;
}

.did-error-input .did-floating-label {
  font-weight: 600;
  color: #9d3b3b;
}


.input-group {
  display: flex;
}

.input-group .did-floating-input {
  border-radius: 0 4px 4px 0;
  border-left: 0;
  padding-left: 0;
}

.btn-signin {
  background: #4549A2;
  border-radius: 7px;
  padding: 10px 40px;
  width: 100%;
  border: none;
  color: #fff;
  font-family: var(---font-style-sans);
font-weight: 500;
font-size: 18px;
line-height: 39px;

}

.icon-border-bg {
  width: 48px;
  height: 48px;



}

.bg-icon {
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background-color: rgba(217, 217, 217, 0.3);
  ;
  border-radius: 50%;
}

.rounded-box {
  border-radius: 30px;
}

.new-user-signup p {
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
}

.new-user-signup p span {
  font-family: var(---font-style-sans);
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  margin-left: 10px;
  color: #4549A2;
}

.signin-heading h1 {
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  font-size: 42px;
  line-height: 55px;
  color: #000000;
}

.signin-heading p {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  color: #000000;
}
.forgot-password p .link-forgot{
  font-family: var(---font-style-sans);
text-decoration: none;
font-weight: 400;
font-size: 17px;
line-height: 26px;
color: #4549A2!important;
}
.remember-checkbox label{
  margin-left: 10px;
  font-family: var(---font-style-sans);
font-weight: 500;
font-size: 18px;
line-height: 27px;
color: #000000;
}
.sign-in-with{
  font-family: var(---font-style-sans);
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
color: #989898;
}
input:checked  {
  background-color: #000;
  border-color: #000;
}
.iconchange{
  position: absolute;
  margin-top: 10px;
  font-size: 24px;
  margin-left: -40px;
  color:#989898 ;
}
 */
:root {
  --primary-color: #E0701A;
  --secondry-color: #112B3C;
  ---font-style-sans: 'IBM Plex Sans', sans-serif;

}

@media screen and (max-width:800px) {
  .main-header {
    position: fixed;
    /* display: inline-flex; */
    z-index: 99;
    padding: 3px 10px !important;
  }

  .breadcrumps-box {
    margin-top: 9px !important;
    height: 31px !important;
  }

  .mobile-sidebar {
    width: 0px !important;
    display: none;
    position: fixed;
    height: 100vh;
  }

  main {
    margin-top: 37px;
  }

  .mob-none {
    display: none;
  }

  .sidebar {
    background: var(--secondry-color);
    color: #fff;
    height: 100vh !important;
    width: 800px;
    transition: none !important;
    position: fixed;
    z-index: 90;
    margin-top: 45px;
  }

  .bars {
    display: flex;
    font-size: 25px;
    margin-left: 00px !important;
    cursor: pointer;
  }

  .mobile-bar {
    margin-left: 00px !important;
    display: flex;
    font-size: 25px;
  }

  .toast {
    position: absolute;
    width: auto !important;
    max-width: 100%;
    right: 140px;
    top: 45px;
    font-size: .875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255) !important;
    background-clip: padding-box;
    border: 1pxsolidrgba(0, 0, 0, .1);
    box-shadow: 0 .5rem 1remrgba(0, 0, 0, .15);
    border-radius: 0.25rem;
  }

  .padding-left-custom-header {
    padding-right: 0 !important;
  }

  .dashboard-user-profile p {
    display: none;

  }

  .icon-profile-dropdown {
    display: none;
  }

  .header-notification-profile {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* flex-direction: row; */
    flex-wrap: nowrap !important;
  }

  .search-profile-form {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

  }

  .search-profile-form-myprofile {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .mob-width-timezone {
    width: 100%
  }

  .search-btn {
    margin-right: 16px !important;

  }

  .search-reset-btn {
    display: flex;
    justify-content: center !important;
    margin-top: 12px;
  }

  .reset-btn {
    margin-right: 0px !important;
  }

  .search-filter-box-custom {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: start !important; */
  }

  .search-field-table {
    font-family: var(---font-style-sans);
    margin-left: 20px;
    width: 200px;
    padding: 7px 7px;
    border: 1px solid #EDEFF0;
  }

  .pagination-box {
    display: flex;
    flex-direction: column;
    justify-content: start !important;
  }

  .material-ui-select {
    font-family: var(---font-style-sans);
    font-style: normal;
    font-weight: 500 !important;
    font-size: 13px !important;
    /* line-height: 30px!important; */
    color: #989898 !important;

  }

  .did-floating-label {
    font-size: 13px !important;
  }

  .did-floating-input {
    width: 94% !important;
  }

  .mui-overide .css-q8hpuo-MuiFormControl-root {
    width: 94% !important;
  }

  .no-file-Choose {
    font-size: 12px !important;
  }

  input[type="file"]+label {
    line-height: 0px !important;
    font-size: 12px !important;
    padding: 22px 84px !important;

  }

  .browse-file-name {
    width: calc(91% - 57px) !important;
  }

  .toast-notification {
    width: 100% !important;
    right: 0px !important;
  }

  .toast-profile {
    width: 100% !important;
    right: 0px !important;
  }

  .close-alert-notification {
    top: auto !important;
    right: 5px !important;
    bottom: 9px !important;
  }

  .otp-align .otp-input input {
    width: 38px !important;
    height: 38px !important;
    gap: 2px;
  }

  .position-btn-sub {
    position: relative !important;
  }

  .align-subuser-form .react-tel-input .form-control {
    height: 46px;
    border-radius: 0px;
    width: 94% !important;
  }

  .align-subuser-form {
    display: flex;
    flex-direction: column !important;
  }

  .rounded-box {
    min-height: 100vh;
    height: auto !important;
    border: 2px solid #E0701A !important;
    border-radius: 3px !important;
  }

  .already-user-login p span {
    font-size: 14px !important;
    margin-left: 5px !important;
  }

  .already-user-login p {
    font-size: 14px !important;
  }

  .signin-heading {
    margin-top: 15px;
  }

  .signin-heading h1 {
    font-size: 27px !important;
  }

  .verification-layout-css {
    margin-top: 30px !important;
  }

  .mobile-verify-content h1 {
    font-size: 17px !important;
    line-height: 24px !important;
  }

  .verification-layout-css h4 {
    line-height: 24px !important;
    font-size: 17px !important;
  }

  .resend-otp {
    font-size: 15px !important;
  }

  .verify-timing {
    font-size: 15px !important;
  }

  .count-status {
    height: auto !important;
    padding-bottom: 5px;
  }

  .cart-content {
    line-height: 20px !important;
  }


  .btn-verified-myprofile-mobile {
    margin-bottom: 26px;
    /* margin-top: 10px; */
  }

  .btn-verify-myprofile {
    margin-bottom: 26px;
    margin-top: 10px;
  }

  .mb-sm-0-cus {
    margin-bottom: 0px !important;
  }

  .btn-verify-myprofile {
    margin-bottom: 26px;
    margin-top: 10px;
  }

  .btn-verified-myprofile-email {
    margin-bottom: 26px;
    margin-top: 10px;
  }

  .avatar-edit .img-profile-edit-mob {
    line-height: 0px !important;
    font-size: 16px !important;
    padding: 10px 18px !important;
  }

  .custom-file {
    width: calc(94% - 77px) !important;
  }

  .mui-select-override .MuiOutlinedInput-root {
    width: 98%;
  }

  .mui-select-override .MuiOutlinedInput-notchedOutline legend span {
    font-size: 10px;
  }

  .mui-select-override .MuiInputLabel-root {
    left: 50px !important;
    color: #989898 !important;
    line-height: 19px;
    margin-top: 0px !important;
  }

  .btn-verify-subuser {

    float: left !important;
    margin-right: 19px !important;
    margin-bottom: 10px;
  }

  .btn-verify-subuser-email {

    float: left !important;
    margin-right: 19px !important;
    margin-bottom: 10px;
  }

  .btn-verified-subuser-email {

    float: left !important;
    margin-right: 19px !important;
    margin-bottom: 10px;
  }

  .btn-verified-subuser-mobile {

    float: left !important;
    margin-right: 19px !important;
    margin-bottom: 10px;
  }

  .mobile-menu-hide {
    display: block !important;
  }

  .desktop-menu {
    display: none !important;
  }

  .icon-login-mob .user-input-icon-eye {
    right: 52px;
  }

  .align-myprofile-form-phone .react-tel-input .form-control {
    width: 94% !important;
  }

  .did-floating-label-content .unstyled {
    width: 94% !important;
  }

  .position-mob-verify-icon .veryfied-icon {
    right: 59px;

  }

  .position-mob-verify-icon .veryfied-icon-false {
    right: 59px;

  }

  .passtooltip-align-mob .tooltip-alignpassword {

    right: 57px;
  }

  .shadow-sm-mob {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  }

  .tooltip-alignpassword {
    right: 15% !important;
  }

  .user-input-icon-eye-subuser {
    right: 8% !important;
  }

  .react-tel-input .form-control::placeholder {
    padding-left: 14px;
    font-family: 'myFont', var(---font-style-sans);
    /* font-family: ; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 30px;
    color: #989898;
  }

}

@media screen and (max-width:1400px) {
  .btn-verify-myprofile {
    position: relative;
    top: 0px;
  }

  .btn-verify-subuser {
    margin-right: 8px !important;
    font-size: 13px !important;
  }

  .btn-verify-subuser-email {
    margin-right: 8px !important;
    font-size: 13px !important;
  }

  .count-status-admin {
    border-left: 8px solid var(--primary-color);
    height: auto;
    min-height: 170px;
    /* max-height: 170px; */
  }

  .alert-points-box span {
    margin-left: 0px !important;
  }

}

@media screen and (max-width:1000px) {
  .line-break-cus {
    line-break: auto !important;
  }
}


@media screen and (max-width:600px) {
  .icon-login-mob .user-input-icon-eye {

    right: 32px !important;
  }
  .wrapper-class {
    width: 90%!important;
  }
  .position-mob-verify-icon .veryfied-icon {
    right: 32px !important;

  }

  .position-mob-verify-icon .veryfied-icon-false {
    right: 32px !important;

  }

  .passtooltip-align-mob .tooltip-alignpassword {
    right: 37px !important;
  }
}

.mobile-menu-hide {
  display: none;
}

.desktop-menu {
  display: block;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

/* select {
  appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) !important;
  background-repeat: no-repeat, repeat !important;
    background-position: right 1.7px top 50%, 5px 16px !important;
    background-size: 8px auto, 100% !important;
    border: none;
    border-radius: 0px;
    padding: 0em 0em 0em 0.35em;
    width: 78px;
} */
input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

/* input[type="tel"]::selection{
  background-color: transparent;
} */
.container {
  display: flex;
}

main {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;

}

.unstyled {
  -webkit-appearance: none;
  width: 96% !important;
}

.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
  /* display: none; */
  -webkit-appearance: none;
}

.footer-link p {
  font-family: var(---font-style-sans);
  font-size: 15px;
  font-weight: 400;
}

.react-tel-input .selected-flag {
  width: 44px !important;
  background-color: #fcf4eb !important;
}

.footer-link p span {
  font-family: var(---font-style-sans);
  font-size: 15px;
  font-weight: 500;
  color: #e0701a;
}

.mobile-bar {
  margin-left: 20px;
  display: flex;
  font-size: 25px;
  cursor: pointer;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 20px;
  cursor: pointer;
}

.mobile-sidebar {
  width: 50px;
  background: var(--secondry-color);
  color: #fff;
  height: auto;

}

.user-input-icon-eye {
  position: absolute;
  top: 13px;
  right: 32px;
  color: #989898;
}

.user-input-icon-eye-subuser {
  position: absolute;
  top: 13px;
  right: 5%;
  color: #989898;
}

.trasition-logo {
  transition: all 1s;
}

.most-shared-profile-heading {
  /* background-color: #112B3C;
  color: #fff;
  font-size: 18px;
  margin-bottom: 0;
  padding: 6px; */
  padding: 0 12px;
  margin-bottom: 0!important;
  color: #000!important;
}

.sidebar {
  background: var(--secondry-color);
  color: #fff;
  /* height: auto; */
  width: 250px;
  z-index: 100;
  /* display: inline-block;
  overflow: auto; */
  transition: all 0.5s;
  /* flex-shrink: 0; */
}

.top_section {
  display: flex;
  align-items: center;
  padding-top: 1px;
  /* height: 0px; */
  width: 200px;
  background-color: #fff;


}
canvas {
  background-color: #fff;
  width: 100%!important;
}
.padding-left-custom-header {
  padding-right: 40px;
  /* padding-bottom: 10px; */
}

.logo {
  font-size: 30px;
  color: var(--secondry-color);
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  /* transition: all 1s; */
  text-decoration: none;
}

.link_text {
  transition: all 1s ease-in-out;
  font-family: var(---font-style-sans);
  font-size: 14px;
}

.padding-left-right-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow: hidden;
}

.link:hover {
  background: var(--primary-color);
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition: all 0.5s;
}

.active {
  background: var(--primary-color);
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.icon,
.link_text {
  font-size: 15px;
  text-decoration: none;
  font-weight: 600;
}

.toast-notification {
  position: absolute;
  width: 400px;
  max-width: 100%;
  right: 230px;
  top: 55px;
  font-size: .875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255) !important;
  background-clip: padding-box;
  border: 1pxsolidrgba(0, 0, 0, .1);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.25rem;
  z-index: 9999;
}

.toast-body {
  font-family: var(---font-style-sans);
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.first-upper-cus {
  text-transform: capitalize !important;
}

.notification-box-cus {
  max-height: 160px;
  height: auto;
  overflow-y: scroll;
}

.toast-body {
  padding: 7px !important;
}

.border-bottom-1 {
  border-bottom: 1px solid;
}

.btn-close-notify {
  position: absolute;
  right: 7px;
  top: 6px;
}

.toast-profile {
  position: absolute;
  width: 350px;
  max-width: 100%;
  padding: 14px;
  right: 70px;
  top: 65px;
  z-index: 999;
  font-size: .875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255) !important;
  background-clip: padding-box;
  border: 1pxsolidrgba(0, 0, 0, .1);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

  border-radius: 2px;
}

.gap-profile {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.gap-profile span {
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.icon-profile {
  font-size: 23px;
  color: #000;
}

.profile-img-text {
  display: flex;
  gap: 4px;
}

.profile-img-text h1 {
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 34px;
  color: #000000;
}

.btn-remove {
  color: var(--secondry-color);
  background-color: none !important;
  border-color: none !important;
}

.mui-overide-badge .MuiBadge-badge {
  background-color: var(--primary-color) !important;
}

/* .css-106c1u2-MuiBadge-badge {
  background-color: var(--primary-color) !important;
} */
.close-alert-notification {
  position: absolute;
  top: 13px;
  right: 20px;
  cursor: pointer;
}

.dashboard-user-profile {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1px;
  cursor: pointer;
}

.dashboard-user-profile p {
  margin-top: 10px;
  margin-left: 10px;
  font-family: var(---font-style-sans);
  font-size: 15px;
  font-weight: 500;
}

.header-notification-profile {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.available-count-heading {
  padding-left: 10px;
  margin-top: 30px;
}

.mui-overide-badge .icon-profile-dropdown {
  margin-top: -10px;
}

.bg-color-profile-user {
  border-radius: 50%;
  /* background-color: var(--primary-color) !important; */
}

.breadcrumps-box {
  background-color: var(--secondry-color);
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.breadcrump-heading p {
  color: #fff;
  margin-top: 15px;
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}

.breadcrumb-link {
  color: #fff;
  margin-top: 15px;
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}

.welcome-message h1 {
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 26px;
  /* line-height: 45px; */
  color: var(--secondry-color);
}

.welcome-message h1 span {
  font-family: var(---font-style-sans);
  font-style: italic;
  font-weight: 600;
  font-size: 26px;
  /* line-height: 45px; */
  color: var(--primary-color);
}

.bg-dashboard {
  background-color: #F5F5F5;
  ;
}

.count-status {
  border-left: 8px solid var(--primary-color);
  height: auto;
  min-height: 150px;
  padding-bottom: 10px;
  background-color: #fff;

}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .count-status {
    border-left: 8px solid var(--primary-color);
    height: auto !important;
    background-color: #fff;
    padding-bottom: 10px;

  }

  .cart-content {
    line-height: 18px !important;
  }
}

.available-count-heading {
  padding-left: 10px;
  margin-top: 30px;
}

.available-count-heading h4 {
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  color: var(--secondry-color);
}
.share-count-heading-top h4 {
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 0px!important;
}

.available-count-heading p {
  font-family: var(---font-style-sans);
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: var(--secondry-color);
}

.count-box {
  display: flex;
  justify-content: space-between;
}

.available-count-status h1 {
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 56px;
  color: var(--secondry-color);
  padding-right: 50px;
  margin-top: 30px;

}

.cart-content {
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  font-style: italic;
  color: #e0701a;
  padding-left: 10px;
}

.searched-table-heading h3 {
  font-family: var(---font-style-sans);
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: var(--secondry-color);
  padding-top: 10px;
}
.status-chart-heading h3 {
  font-family: var(---font-style-sans);
  font-weight: 700;
  font-size: 16px;
  line-height: 5px;
  color: var(--secondry-color);
  padding-top: 5px;
}

table th {
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* width: 1000000000px; */
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  color: var(--secondry-color);
  /* min-width: 10px; */
  padding: 10px;

  /* width: 2302980234234898923434234793879823409387423348379203948723934709387423819092372984.062px; */


}

/* input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
    background-color: red;
    -moz-background-color:#ed2;
    color: white;
    outline: none;
} */

.react-tel-input .country-list .country {
  background-color: transparent !important;
}

#howitswork {
  scroll-margin-top: 70px;
}

.offered-select-option:focus {
  outline-style: none;
}

.tooltip-inner {
  text-align: left !important;
}

.offered-select-option {
  word-wrap: normal;
  background: #E0701A;
  color: #fff;
  border: none;
  font-size: 15px;
  padding: 4px;
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* appearance: none; */
}

.offered-select-option option:not(:checked) {
  background-color: #E0701A !important;
}

.offered-select-option option:checked {
  background-color: rgb(110, 110, 110);
}

/* .wrapper {
  overflow-x: auto;
  white-space: nowrap;
}

.wrapper table {
  width: 2000px;
  min-width: 100%;
} */
.search-table,
td,
th {
  border-collapse: collapse;
  border: 1px solid #EDEFF0;
}

/* 
.search-table{table-layout: fixed; margin:40px auto 0px auto; }

th{padding:20px 7px; font-size:15px; color:#444; background:#66C2E0;}
td{padding:5px 10px; height:35px;}

.search-table-outter { overflow-x: scroll; }
th, td { min-width: 250px; }
.search-table, td, th{border-collapse:collapse; border:1px solid #EDEFF0;} */
.p-l-r-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

/* .wrapper{
  width: 1600px;
  max-width: 90%;
} */
/* .share-table tr td {
  min-width:100px;
} */

td {
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  /* line-height: 21px; */
  color: var(--secondry-color);
  text-align: left;
  /* min-width: 120px; */
  padding: 13px !important;
}

.cus-mr-5 {
  margin-right: 100px;
}

.searched-table {
  margin-top: 10px;
}

.search-name-text-color {
  color: var(--primary-color);
}

.intericon-color {
  color: #808080;
  cursor: pointer;
}

.text-white-breadcrums {
  color: #fff !important;
  font-size: 13px !important;

}

.cus-mr-5 {
  margin-right: 50px !important;
}

.breadcrumps-links {
  color: #3174d8 !important;
  text-decoration: none;
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}


.did-floating-label-content {
  position: relative;
  margin-bottom: 20px;
}

.did-floating-label {
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #989898;
  position: absolute;
  pointer-events: none;
  left: 60px;
  top: 9px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-input,
.did-floating-select {
  font-family: var(---font-style-sans);
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: var(--secondry-color);
  width: 96%;
  height: 46px;
  padding: 0 55px;
  background: #fff;

  border: 1px solid #D9D8D8;
  border-radius: 0px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.did-floating-input:focus,
.did-floating-select:focus {
  outline: none;
  border: 1px solid #E0701A;
  ;
}

.did-floating-input:focus~.did-floating-label,
.did-floating-select:focus~.did-floating-label {
  top: -16px;
  font-size: 13px;
  color: #E0701A;
  ;


}

.did-floating-input:focus+.icon-background {
  border: 1px solid #E0701A;
  ;
  border-left: none;
}



.did-floating-input:not(:placeholder-shown)~.did-floating-label {
  top: -16px;
  font-size: 13px;

}





.did-error-input .did-floating-input,
.did-error-input .did-floating-select {
  border: 2px solid #9d3b3b;
  color: #9d3b3b;
}

.did-error-input .did-floating-label {
  font-weight: 600;
  color: #9d3b3b;
}


.input-group {
  display: flex;
}

.input-group .did-floating-input {
  border-radius: 0 4px 4px 0;
  border-left: 0;
  padding-left: 0;
}

.btn-signin {
  background: #4549A2;
  border-radius: 7px;
  padding: 10px 40px;
  width: 100%;
  border: none;
  color: #fff;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 18px;
  line-height: 39px;

}

.search-profile-form {
  z-index: -999;
}

.user-input-icon {
  position: absolute;
  top: 13px;
  left: 15px;
  color: #525F7F;


}

.user-input-icon-myprofile {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #525F7F;
}

.icon-background {
  border: 1px solid #D9D8D8;
  border-left: none;
  background-color: #FCF4EB;
  ;
  ;
  width: 46px;
  height: 46px;
  position: absolute;
  top: 0px;
  left: 1px;
}

.search-profile-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}

.search-profile-form-myprofile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: -999;
}

.search-reset-btn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.search-btn {
  margin-right: 30px;
  background-color: #F7A340;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding: 10px 30px;
  border: none;
  color: #FFFFFF;
}

.search-btn-que {
  background-color: #F7A340;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding: 5px 7px;
  width: 100%;
  border: none;
  color: #FFFFFF;
  margin-top: 0px;
  height: 38px;
}

.reset-btn {
  margin-right: 30px;
  background-color: #AAAAAA;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding: 10px 30px;
  border: none;
  border-radius: 0px !important;
  color: #FFFFFF;
}

.btn-offer-modal {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
  border: none;
  padding: 6px 20px;
  background-color: #E0701A;
}

.modal-heading {
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
}

.modal-header {
  border-bottom: none !important;
}

.search-field-table {
  font-family: var(---font-style-sans);
  margin-left: 20px;
  padding: 7px 20px;
  border: 1px solid #EDEFF0;
}

.search-field-table:focus {
  border: 1px solid #EDEFF0;
  outline-style: none;
}

/* .icon-background-foucs{
  border: 1px solid #4549A2;
  border-left: none;
  background-color: #F7FAFC;;
  width: 50px;
  height: 55px;
  position:absolute;
  top:0px;
  left: 1px;
} */
.search-filter-box-custom {
  display: flex;
  justify-content: space-between;
}

.sorting-select:focus {
  outline-style: none;
}

.sorting-select {
  width: 200px !important;
  font-family: var(---font-style-sans);
  margin-left: 20px;
  padding: 7px 20px;
  font-size: 15px !important;
  border: 1px solid #EDEFF0;
}

/* Hidden select default on ie */
select::-ms-expand {
  display: none;

}

/* Style for wrap select arrow */
.wrap-select-arrow {
  position: relative;
}

/* Style for arrow */
.select-arrow {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 15px;
  pointer-events: none;
}

/* Arrow down */
.arrow-down {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #4A4A4A;
}

/* Arrow up */
.arrow-up {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #4A4A4A;
  margin-bottom: 3px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3174D8 !important;
  border-color: #EDEFF0 !important;
}

.page-link {
  padding: 4px 9px !important;
  font-family: var(---font-style-sans);
  font-size: 14px;
}

.pagination-showing-count-status p {
  font-family: var(---font-style-sans);
  color: #4F4F50;
}

.pagination-box {
  display: flex;
  justify-content: space-between;
}

.alert-points-box {
  padding: 7px !important;
  background-color: #FFE3CE !important;
  border: 1px solid #E0701A !important;
  border-radius: 2px !important;
  color: #E0701A !important;
  font-family: var(---font-style-sans);
  font-style: italic;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
}

.alert-points-box-myprofile {
  padding: 7px !important;

  border: 1px solid #00944f !important;
  border-radius: 2px !important;
  color: #00944f !important;
  font-family: var(---font-style-sans);
  font-style: italic;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
}

#aboutus {
  scroll-margin-top: 100px;
}

.alert-points-box span {
  margin-left: 10px;
}




select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-select:focus:checked+.did-floating-label {
  top: -11px;
  font-size: 13px;
}

.did-floating-select:focus+.icon-background {
  border: 1px solid #E0701A;

  border-left: none;

}


.did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 7px top 50%;
  background-repeat: no-repeat;
}

.did-floating-select {
  padding-left: 60px;
}

input[type="date"] {
  font-size: 14px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* input[type=date]::-webkit-datetime-edit-text {
 font-size: 16px!important;
}
input[type=date]::-webkit-datetime-edit-month-field{
 font-size: 16px!important;
}
input[type=date]::-webkit-datetime-edit-day-field {
 font-size: 16px!important;
}
input[type=date]::-webkit-datetime-edit-year-field {
 font-size: 16px!important;
} */



/* .input-select {
  position: relative;
  display: block;
  background: #ddd;
  
}
.input-select::after {
  content: "▼";
  position: absolute;
  right: 1.5rem;
  top: 1.6rem;
  transition: 300ms transform 200ms;
}
.input-select:active::after {
  transform: rotate(-180deg);
}
.input__field {
  display: block;
  opacity: 1;
  width: 100%;
  box-sizing: boder-box;
  border: 0;
  background: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 10px 30px;
  border: 2px solid purple;
  border-radius: 0;
  transition-delay: 0ms;
  will-change: color;
  transition: 200ms color linear;
}
.input__field:invalid {
  color: transparent;
  transition: 200ms color linear 100ms;
}
.input__field:valid + .input__label, .input__field:focus:valid + .input__label {
  transform: scale(0.65) translate(0.5em, -0%);
  top: -30px;
  width:200px;
  background-color: #fff;
  transition: 200ms transform ease-out;
  color: purple;
}
.input__label {
  position: absolute;
  left: 0;
  top: -12px;
  right: 0;
  padding: 1.5rem 1.5rem 0;
  pointer-events: none;
  transform-origin: 0 0;
  width: auto;
  transition: 200ms transform ease-out 200ms;
  will-change: transform;
} */
.mui-select-override .MuiOutlinedInput-root {
  border-radius: 1px;
}

.mui-select-override .MuiInputLabel-root {
  left: 48px;
  color: #989898;
  line-height: 19px;
  margin-top: -3px;
}

.mui-select-override .MuiInputLabel-root.Mui-focused {
  left: 48px !important;
  color: #E0701A !important;
}

.mui-select-override .MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #000 !important;
  color: #000;
}

/* .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  border-radius: 1px!important;
} */
/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  left: 48px!important;
} */
/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  left: 48px!important;
} */
.mui-select-override .MuiOutlinedInput-notchedOutline {
  padding: 0 56px !important;
}

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  padding: 0 56px!important;
} */
.mui-select-override .MuiFormControl-root {
  width: 96%;
}

.mui-select-override .MuiFormControl-root.Mui-hover {
  border: none !important;
}

/* .css-q8hpuo-MuiFormControl-root{
  width: 96%!important;
} */
.material-ui-select {
  font-family: var(---font-style-sans) !important;
  font-style: normal;
  font-weight: 500 !important;
  /* font-size: 18px!important; */
  /* line-height: 30px!important; */
  color: #989898 !important;

}

/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root:hover{
  border: none;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
  color: #989898!important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color:  #E0701A!important;
} */
.mui-select-override .MuiOutlinedInput-root {
  font-size: 17px !important;
  height: 46px;
}

.mui-select-override .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #D9D8D8;
}

/* .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  font-size: 19px!important;
  height: 55px;
} */
/* .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border: 1px solid #E0701A!important;
} */
/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused ~ .icon-background{
  border: 1px solid #E0701A!important;
  
  border-left: none!important;

 
} */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #E0701A !important;
}

.mui-select-override .MuiOutlinedInput-root.Mui-focused+.icon-background {
  border: 1px solid #E0701A !important;

  border-left: none !important;
}

.mui-select-override .MuiSelect-select {
  padding: 0 59px;
  font-size: 15px !important;
}

.hover-border-0:hover {
  border: none !important;
}

.custom-file-control::after {
  content: "Choose file...";
}

.custom-file-control::before {
  content: "Browse";
}

/* 
input[type="file"] {
  border: 2px solid #333;
  display: none;
}

input[type="file"]+label {
  padding: 5px 30px;
  border-radius: 2px;
  cursor: pointer;
  background: #FCF4EB;
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 46px;
  line-height: 32px;
  color: #525F7F;
  border: 1px solid #D9D8D8 !important;
}

.browse-file-name {
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #525F7F;
  border: 1px solid #D9D8D8 !important;
  width: calc(95% - 100px);
  height: 46px;
  display: flex;
  align-items: end;
  padding: 0 20px;
}

.no-file-Choose {
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #828282;
  margin: 10px;

} */

/* .pos{
  position: relative;
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
} */


/* .file_upload {
  position: relative;
  min-width: 90px;
  text-align: center;
  color: #ee3333;
  line-height: 25px;
  background: #fff;
  border: solid 2px #ee3333;
  font-weight: 600;
}

a.file_upload {
  display: inline-block;
}

.file_upload .btn_lbl {
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.file_upload .btn_colorlayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1;
  pointer-events: none;
}

.file_upload input[type="file"] {
  position: absolute;
  top: 3px;
  left: -86px;
  font-weight: 600;
  margin-left: 100%;
  color: #ee3333;
  outline: none;
} */


.custom-file {
  position: relative;
  display: inline-block;
  width: calc(95% - 70px);
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 25px;
  left: 21px;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 102%;
  height: calc(1.5em + 0.75rem + 2px);

  /* overflow: hidden; */
  opacity: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 3.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-file-label {
  position: absolute;
  top: 0px;
  right: -55px;
  left: -21px;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 14px);
  padding: 0.65rem 5.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ced4da;
  /* border-radius: 0.25rem; */
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #868686;
}

.custom-file-label::after {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.75rem);
  padding: 10.375px 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #fcf4eb;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
  font-family: var(---font-style-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #525F7F;
  border: 1px solid #D9D8D8 !important;

}

.defauk-suggest {

  /* position: absolute; */
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;


}

.suggestion {
  cursor: pointer;
  border: 1px;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}

.suggestion:hover {
  background-color: grey;
}

.autocomplete-root .MuiAutocomplete-root fieldset:hover {
  border-color: #2196F3 !important;
}

.autocomplete-root .MuiAutocomplete-root {
  width: 96%;
  height: 46px;
}

.autocomplete-root .MuiOutlinedInput-notchedOutline .autocomplete-root .MuiOutlinedInput-notchedOutline:hover {
  border-color: #2196F3 !important;
}

.autocomplete-root .MuiInputLabel-root {
  left: 48px;
}

.autocomplete-root .MuiOutlinedInput-notchedOutline {
  padding: 0 56px !important;
}

.autocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0 56px !important;
  height: 46px;
}

.autocomplete-root .MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px;
}

.autocomplete-root .icon-background {
  margin-top: 0;
}

.autocomplete-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline+.icon-background {
  border: #000000;
}

.autocomplete-root .MuiInputLabel-root.Mui-focused {
  color: #E0701A
}

.autocomplete-root .MuiOutlinedInput-root.Mui-focused+.icon-background {
  border: 1px solid #E0701A !important;

  border-left: none !important;
}

.form-error {
  color: rgb(226, 47, 47) !important;
  font-family: var(---font-style-sans) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}



.autocomplete-root .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #D9D8D8;
}

::-webkit-datetime-edit-month-field {
  background-color: transparent;
  color: #000
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input+label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview>img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.myprofile-link {
  color: #000;
  text-decoration: none;
}

.myprofile-link:hover {
  color: #000;
}

.bg-signup {
  background: url('./assets/img/signupbg.png');
  min-height: 100vh;
  background-size: cover;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.signup-box-css {
  height: 50vh;
  background-color: #000000;
}

.rounded-box {
  min-height: 85vh;
  border: 7px solid #E0701A;
  border-radius: 15px;
  height: auto;
}

.rounded-box2 {
  height: auto;
  min-height: 50vh;
  border: 7px solid #E0701A;
  border-radius: 15px;
}

.about-content {
  margin: 0 auto !important;
}

.rounded-box3 {
  height: 30vh;
  border: 7px solid #E0701A;
  border-radius: 15px;
}

.signin-heading h1 {
  font-weight: 600;
  font-size: 35px;
  line-height: 55px;
  color: #000000;
  font-family: var(---font-style-sans) !important;
}

.signin-heading h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 55px;
  color: #000000;
  font-family: var(---font-style-sans) !important;
}

.signin-heading p {
  font-family: var(---font-style-sans) !important;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
}

.already-user-login p {
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
  font-family: var(---font-style-sans) !important;
  color: #000000;
}

.already-user-login p span {
  margin-left: 10px;
  color: #E0701A;
  font-weight: 600;
  font-size: 17px;
  text-decoration: none;
  line-height: 32px;
  font-family: var(---font-style-sans) !important;
}

.link-text-decoration {
  text-decoration: none;
}

.align-signup-form {
  justify-content: center;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.align-verify {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.btn-verify {
  /* margin-right: 30px; */
  background-color: #E0701A;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 17px;
  line-height: 10px;
  padding: 5px 10px;
  height: 46px;
  border: none;
  color: #FFFFFF;
}

.btn-verify-subuser {
  background-color: #E0701A;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  padding: 5px 10px;
  height: 46px;
  border: none;
  color: #FFFFFF;
  float: right;
  margin-right: 31px;
  margin-bottom: 10px;
}

.btn-verify-subuser-email {
  background-color: #E0701A;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  padding: 5px 10px;
  height: 46px;
  border: none;
  color: #FFFFFF;
  float: right;
  margin-right: 31px;
  margin-bottom: 10px;
}

.btn-verified-subuser-email {
  background-color: #27A243;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding: 5px 10px;
  height: 46px;
  border: none;
  color: #FFFFFF;
  float: right;
  margin-right: 31px;
  margin-bottom: 10px;
}

.btn-verified-subuser-mobile {
  background-color: #27A243;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding: 5px 10px;
  height: 46px;
  border: none;
  color: #FFFFFF;
  float: right;
  margin-right: 31px;
  margin-bottom: 10px;
}

.btn-verify-myprofile {
  background-color: #E0701A;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  width: 84%;
  height: 46px;
  border: none;
  color: #FFFFFF;
}

.btn-verified-myprofile-mobile {
  background-color: #27A243;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  width: 84%;
  height: 46px;
  border: none;
  color: #FFFFFF;
}

.btn-verified-myprofile-email {
  background-color: #27A243;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  width: 84%;
  height: 46px;
  border: none;
  color: #FFFFFF;
}

.backtosingin {
  font-size: 17px;
  color: #F7A340;
  font-family: var(---font-style-sans);
  font-weight: 400;
  line-height: 35px;
  padding-top: 10px;
  cursor: pointer;
}

.btn-forgotpassword {
  background-color: #E0701A;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  /* padding: 5px 10px; */
  width: 96%;
  height: 46px;
  border: none;
  color: #FFFFFF;
}

.verification-layout-css {
  border: 2px solid #767474;
  text-align: center;
}

.verification-layout-css h4 {
  font-size: 21px;
  font-family: var(---font-style-sans);
  font-weight: 500;
  line-height: 35px;
  padding-top: 10px;
}

.mobile-verify-content {
  background-color: #D9D9D9;
  width: 100%;
  text-align: center;
}

.mobile-verify-content h1 {
  font-size: 21px;
  font-family: var(---font-style-sans);
  font-weight: 500;
  line-height: 35px;
  padding-top: 10px;
}

.otp-align {
  display: flex;
  justify-content: center;
}

.resend-otp {
  font-size: 17px;
  font-family: var(---font-style-sans);
  font-weight: 500;
  line-height: 35px;
  padding-top: 10px;
}

.resend-otp p span {
  font-size: 17px;
  color: #E0701A;
  font-family: var(---font-style-sans);
  font-weight: 500;
  line-height: 35px;
  padding-top: 10px;
  cursor: pointer;
}

.verify-timing p .counter {
  font-size: 17px;
  color: #E0701A;
  font-family: var(---font-style-sans);
  font-weight: 500;
  line-height: 35px;
  padding-top: 10px;
  display: flex;
  position: relative;
}

.counter {
  color: #E0701A;
}

.verify-timing {
  font-size: 17px;
  font-family: var(---font-style-sans);
  font-weight: 500;
  line-height: 35px;
  padding-top: 10px;
  display: flex;
  gap: 6px;
  justify-content: center;
}

.count-status-admin {
  border-left: 8px solid var(--primary-color);
  height: 150px;

  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.align-register-form-mobile {
  gap: 5px;
  width: 50%;
  display: flex;
}

.bg-theme {
  background-color: #f7a340;
}

@media (min-width: 206px) {
  .align-register-form-mobile .mobile-number-country-code {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media screen and (max-width : 992px) {
  .align-register-form-mobile {
    width: 100% !important;
  }

}

input[type="checkbox"] {
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */

  /* Styling checkbox */
  width: 16px;
  height: 16px;
  background-color: #E0701A;
}

input[type="checkbox"]:checked {
  background-color: #E0701A;
}

input[type="checkbox"] {
  accent-color: #E0701A;
}

.btn-register-form {
  background-color: #E0701A;
  font-family: var(---font-style-sans);
  font-weight: 500;
  font-size: 17px;
  line-height: 10px;
  padding: 5px 50px;
  height: 46px;
  border: none;
  color: #FFFFFF;
}

.terms-condition-check {
  display: flex;
  gap: 9px;
  justify-content: center;
}

.checkbox-termsandcondition {
  margin-top: 5px;
}

.terms-condition-check p {
  font-family: var(---font-style-sans);
  font-size: 15px;
  font-weight: 400;
}

.password-tooltip-align {
  position: absolute;
  top: 14px;
  right: 31px;
}

.tooltip-alignpassword {
  position: absolute;
  top: 7px;
  z-index: 0;
  right: 11%;
}

.profile-img-navbar {
  width: 40px !important;
  height: 40px !important;
  object-fit: cover !important;
  border-radius: 50%;
}

.profile-img-avatar {
  width: 40px !important;
  height: 40px !important;
  object-fit: cover !important;
}

.react-tel-input .form-control::placeholder {
  padding-left: 14px;
  font-family: 'myFont', var(---font-style-sans);
  /* font-family: ; */
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: #989898;
}

.veryfied-icon {
  position: absolute;
  top: 15px;
  right: 6%;
  color: #27A243;

}

.veryfied-icon-false {
  position: absolute;
  top: 15px;
  right: 39px;
  color: #da1a1a;
}

.align-signup-form .react-tel-input .form-control {
  height: 46px;
  border-radius: 0px;
}

.align-signup-form .react-tel-input .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #E0701A !important;
  outline: 0;
  box-shadow: none;
}

.align-signup-form .react-tel-input .form-control:focus+.flag-dropdown {
  border-color: #E0701A !important;
}

.userManagement-phoneInput-css .react-tel-input .form-control:focus+.flag-dropdown {
  border-color: #E0701A !important;
}

.userManagement-phoneInput-css .react-tel-input .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #E0701A !important;
  outline: 0;
  box-shadow: none;
}

.userManagement-phoneInput-css .react-tel-input .form-control {
  height: 46px;
  border-radius: 0px;
  width: 96%;
}

.align-subuser-form .react-tel-input .form-control {
  height: 46px;
  border-radius: 0px;
  width: calc(100% - 1px);
}

.align-subuser-form .react-tel-input .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #E0701A !important;
  outline: 0;
  box-shadow: none;
}

.align-subuser-form .react-tel-input .form-control:focus+.flag-dropdown {
  border-color: #E0701A !important;
}

.align-subuser-form {
  display: flex;
}

.position-btn-sub {
  position: absolute;
  top: 0px;
  right: 12px
}

.react-tel-input .country-list .country-name {
  color: #000;
}

.align-myprofile-form-phone .react-tel-input .form-control {
  height: 46px;
  border-radius: 0px;
  width: 96%;
  font-family: var(---font-style-sans);
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
}

.align-myprofile-form-phone .react-tel-input .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #E0701A !important;
  outline: 0;
  box-shadow: none;
}

.align-myprofile-form-phone .react-tel-input .form-control:focus+.flag-dropdown {
  border-color: #E0701A !important;
}

.styel-organization-icon {
  font-size: 20px;
  left: 12px;
}

.modal-body canvas {
  width: 100% !important;
  height: auto !important;
}

.btn-align-imgview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-pdf__message.react-pdf__message--error {
  display: none !important;
  text-align: center;
}

.waterMark {
  transform: rotate(-43deg);
  position: absolute;
  top: 37%;
  left: 18%;
  font-size: 125px;
  opacity: 0.2;
  font-weight: 600;
  color: #e0701a;
}

.positioning-spinner-imgView {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.viewIcon-file {
  font-size: 20px;
  width: 25px;
  color: #000;
}

@media screen and (min-width: 576px) {
  .image-view .img-modal {
    max-width: 50%;
  }
}


.title {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  font-size: 37px;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-weight: 900
}

.terms_condition_css_page {
  font-size: 16px !important;
  line-height: 28px !important;
  color: #7f7f7f !important;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 15px !important;
  font-weight: 600 !important;
}

.lastupdateOn-css {
  font-size: 16px !important;
  line-height: 28px !important;
  color: #158ff9 !important;
  font-weight: 600 !important;
  margin-bottom: 15px !important;
  font-family: 'Roboto', sans-serif !important;
}

.terms-para-tag-css p {
  font-size: 16px;
  font-weight: normal;
  line-height: 28px;
  color: #7f7f7f;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
}

.terms-para-tag-css h4 {
  font-size: 18px;
  font-weight: normal;
  line-height: 28px;
  color: #7f7f7f;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
}

.footer-area-terms {
  background: #eef1f6;
  padding-top: 50px;
  padding-bottom: 60px;
}

/* .appie-sticky{
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999999;
  background: #fff;
  transition: all 0.35s ease;
  scroll-behavior: smooth;
} */
.text-color-privacy-terms {
  color: #e0701a;
  text-decoration: none;
}

.line-break-cus {
  line-break: anywhere;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  width: 96%;
}

.editor-class {
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  border: 1px solid #ccc;
}

.toolbar-class {
  border: 1px solid #ccc;
}

.rdw-dropdown-selectedtext {
  color: #000;
  text-decoration: none;
}

.add-questionn-cus {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.question-input {
  width: 96%;
  padding: 8px 20px;
  border: 1px solid;
}

.question-input-container {
  width: 100%;
  text-align: center;
}

.add-editor-cancel-align {
  display: flex;
  justify-content: flex-end;
}

.notes-updated-times-align h4 {
  font-size: 18px;
  font-weight: 800;
  font-family: var(---font-style-sans);
}

.notes-updated-times-align h4>span {
  font-family: var(---font-style-sans);
  font-size: 16px;
  font-weight: 400;
}

.notes-box-align {
  display: flex;
  flex-direction: column;
}

.notes-text-styles p {
  font-family: var(---font-style-sans);
  font-size: 16px;
  font-weight: 400;
}

.notes-action--box-align {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: -15px;

}

.notes-action--box-align p {
  color: #828282;
  font-family: var(---font-style-sans);
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.align-interview-documents {
  display: flex;
  margin-top: 10px;
}

.border-img-interviewdocs {
  border: 1px solid #989888;
  padding: 4px;
  width: 37px;
  border-radius: 3px;
}

.title-interview-docs h4 {
  font-size: 18px;
  font-weight: 800;
  font-family: var(---font-style-sans);
}

.title-interview-docs-resume h4 {
  font-size: 16px;
  font-weight: 700;
  font-family: var(---font-style-sans);
}

.font-weight-bold-cus {
  font-weight: bold;
}

.searched-table tr .font-weight-bold-cus {
  padding: 2px !important;
}

/* .form-container {
  display: flex;
  flex-wrap: wrap;
}

.form-column {
  width: 30%;
}

.form-field {
  margin-bottom: 10px;
}

.form-label {
  font-weight: bold;
  display: inline-block;
  width: 180px;
}

.form-value {
  display: inline-block;
} */