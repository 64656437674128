.select-dropdown-ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    position: absolute;
    width: 96%;
    z-index: 1;
    height: auto;
    min-height: 100px;
    max-height: 150px;
    overflow-y: auto;
}

.select-dropdown-li {
    padding: 10px;
    cursor: pointer;
}

.select-dropdown-li:hover {
    background-color: #f2f2f2;
}

.color-delete-btn {
    color: #E93731;
    cursor: pointer;
}

.delete-btn-align {
    display: flex;
    gap: 10px
}

.btn-delete-que {
    background-color: #E93731;
    font-family: var(---font-style-sans);
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding: 6px 30px;
    border: none;
    color: #FFFFFF;
    height: 35px;
    /* margin-top: 17px; */
}

.select-category-dropdown-err {
    position: absolute;
    top: 50%;
    right: 70px;
    transform: translateY(-110%) !important;
    pointer-events: none;
}

.select-category-dropdown {
    position: absolute;
    top: 50%;
    right: 70px;
    transform: translateY(-50%);
    pointer-events: none;
}

@media screen and (max-width:900px) {
    .add-questionn-cus {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }

    .search-btn-que {
        width: 100px;
    }
}

@media screen and (max-width:800px) {
    .add-questionn-cus {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: row;
    }

    .inpt-question-mob {
        width: 100%;
    }

    .select-category-dropdown {
        right: calc(12% - 7px);
    }

    .btn-delete-que {
        /* margin-top: 17px !important; */
    }

    .search-btn-que {
        width: 100px !important;
    }


}

@media screen and (max-width:600px) {
    .btn-delete-que {
        width: 100px;
    }

    .delete-btn-align {
        flex-direction: column;
        align-items: center;
    }
}