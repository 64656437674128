.accordion {
    /* border: 1px solid #ddd; */
    border-radius: 4px;
    margin-bottom: 20px;
}
.accordion .accordion-item{
margin-bottom: 20px;
}
.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.accordion-header h3 {
    margin: 0;
}

.icon-accordion {
    width: 12px;
    height: 12px;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
    transform: rotate(135deg);
    transition: transform 0.3s;
    margin-right: 10px;
}

.icon-accordion.open {
    transform: rotate(-45deg);
}

.accordion-content {
    padding: 10px;
}